.cs-logo {
  height: 50px;
}

.nav-bar {
  background-color: rgba(0,0,0,.03);
  border-top: 6px solid #38B885;
}

.body {
  margin-top: 30px;
}

.card {
  border-left: 6px solid #38B885;
  margin: 0 auto; 
  float: none; 
  margin-bottom: 10px; 
}

.AmplifySignOut {
  color:  #38B885;
}

.AmplifySignIn {
  color:  #38B885;
}

.AmplifySignUp {
  color:  #38B885;
}